.root {
  min-height: 100%;
  display: flex;
}

.container {
  min-height: 100%;
  padding-top: 5px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 5 !important;
    padding-top: 5px;
    padding-bottom: 30px;
  }
}
