.contenedor {
    margin-top: 50px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.button-container {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}