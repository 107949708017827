.contenedor {
  margin-top: 50px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.image {
  filter: grayscale(0.8);
}

.text {
  text-align: justify;
  color: #958a8a;
}

.stack {
  margin-top: 100px;
}
