.contenedor {
    margin-top: 50px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.buttonContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttonContainer>* {
    min-width: 400px;
}

.texto {
    text-align: justify;
}

.view {
    padding: 50px 0;
}

.container {
    padding: 20px;
}