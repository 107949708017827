.container {
  padding: 20px;
}

.responseField {
  margin-right: 15px;
  margin-block: 0;
  text-decoration-style: dotted;
  padding: 0 15px;
  border-bottom: 1px dotted #000;
}
