.paper {
    margin: 20px 0;
    padding: 50px;
    display: flex;
    flex-direction: column;
}
.button-container {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#startEvaluacionCheck {
    margin-top: 0px !important;
    margin-left: -10px;
}

#startEvaluationStudentSelector {
    min-width: 400px;
}

@media only screen and (max-width: 600px) {
  .paper {
    margin-top: 20px;
    min-height: 200px;
    display: flex;
    flex-direction: column !important;
    padding: 20px;
  }

  .container {
      padding: 5 !important;
  }

  #startEvaluationStudentSelector {
    min-width: 100%;
}

}
