.contenedor {
  margin-top: 20px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.buttonContainer>* {
  min-width: 400px;
  max-width: '50%';
  margin-right: 25px;

}

@media only screen and (max-width: 600px) {
  .contenedor {
    margin-top: 20px;
    min-height: 200px;
    display: flex;
    flex-direction: column !important;
    padding: 15px;
  }

  .buttonContainer>* {
    min-width: 0;
    width: 100%;
  }
}