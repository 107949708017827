.container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 400px));
  grid-gap: 20px;
  justify-content: space-around;
}

.cardActions {
  justify-content: space-evenly;
}
