.contenedor {
  margin: 25px 0;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonContainer button {
  min-width: 500px !important;
}

.texto {
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .buttonContainer {
    align-items: center;
  }

  .buttonContainer button {
    min-width: 100% !important;
  }
}
