.text blockquote {
  position: relative;
  padding-left: 1em;
  border-left: 0.2em solid black !important;
  font-family: 'Roboto', serif;
  margin-inline-start: 15px;
}

.text blockquote::before {
  font-family: 'Sanchez';
  color: lighten(black, 40%);
}

.text blockquote::after {
  font-family: 'Sanchez';
  color: lighten(black, 40%);
}

.paper {
  margin: 20px 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.title > p {
  
  margin-block-end: 0 !important;
  margin-block-start: 0 !important;
  text-align: 'left'
}

@media only screen and (max-width: 600px) {
  .paper {
    margin-top: 20px;
    min-height: 200px;
    display: flex;
    flex-direction: column !important;
    padding: 20px;
  }
}
